import { Alert, Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ChallengePolicy, GameBot } from "../../api/model";
import * as api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMapBudgets,
  getMapCentralness,
  getMapNegative,
  getMapPens,
  getMapSizes,
  validateMapName,
} from "../../utils/map-combinations";

export default function PlayBot() {
  const [mapSize, setMapSize] = useState<string>("medium");
  const [mapBudget, setMapBudget] = useState<string>("medium");
  const [mapPens, setMapPens] = useState<string>("medium");
  const [mapCentralness, setMapCentralness] = useState<string>("medium");
  const [mapNegative, setMapNegative] = useState<string>("no");
  const [bots, setBots] = useState<GameBot[] | undefined>();
  const [myBot, setMyBot] = useState<GameBot | null>(null);
  const [otherBot, setOtherBot] = useState<GameBot | null>(null);
  const [alertValue, setAlertValue] = useState<string | null>(null);
  const toChallenge = useParams().botid;
  const navigate = useNavigate();

  useEffect(() => {
    api.getGameBots().then((result) => {
      setBots(result);
      if (toChallenge) {
        const foundBots = result.filter(
          (bot) => bot.id.toString() == toChallenge
        );
        if (foundBots.length > 0) {
          setOtherBot(foundBots[0]);
        } else if (isNaN(parseInt(toChallenge))) {
          const mapNameParts = validateMapName(toChallenge);
          if (mapNameParts != null) {
            setMapSize(mapNameParts.size);
            setMapBudget(mapNameParts.budget);
            setMapPens(mapNameParts.pens);
            setMapCentralness(mapNameParts.centralness);
            setMapNegative(mapNameParts.negative);
          }
        }
      }
    });
  }, []);

  function startGame() {
    if (myBot == null || otherBot == null) {
      return;
    }
    api
      .startGame(
        `${mapSize}-${mapBudget}-${mapPens}-${mapCentralness}-${mapNegative}`,
        myBot.id,
        otherBot.id
      )
      .then((result) => {
        if (result.needsConfirmation) {
          setAlertValue(
            "Request submitted. The other player will have to accept this first."
          );
        } else {
          navigate(`/my-bots/${myBot.id}`);
        }
        setTimeout(() => {
          setAlertValue(null);
        }, 15000);
      });
    setMyBot(null);
    setOtherBot(null);
  }

  if (bots === undefined) {
    return <div>Loading...</div>;
  }

  const myBots = bots.filter((bot) => bot.myBot);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div>
          <h1 className="mb-3">Start new game</h1>
        </div>
        <div>
          <Button
            disabled={myBot == null || otherBot == null}
            onClick={startGame}
          >
            Start game
          </Button>
        </div>
      </div>
      {alertValue != null && <Alert variant="success">{alertValue}</Alert>}
      {toChallenge &&
        !otherBot &&
        !myBot &&
        !isNaN(parseInt(toChallenge)) &&
        bots.length > 0 &&
        bots.filter((bot) => bot.id.toString() == toChallenge).length == 0 && (
          <Alert variant="danger">
            This bot does not allow anyone to play against it.
          </Alert>
        )}
      <Form className="mt-3" action="#">
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Map size</Form.Label>
            <Form.Select
              value={mapSize}
              onChange={(e) => setMapSize(e.currentTarget.value)}
            >
              {getMapSizes().map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Budget</Form.Label>
            <Form.Select
              value={mapBudget}
              onChange={(e) => setMapBudget(e.currentTarget.value)}
            >
              {getMapBudgets().map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Pens</Form.Label>
            <Form.Select
              value={mapPens}
              onChange={(e) => setMapPens(e.currentTarget.value)}
            >
              {getMapPens().map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Centralness</Form.Label>
            <Form.Select
              value={mapCentralness}
              onChange={(e) => setMapCentralness(e.currentTarget.value)}
            >
              {getMapCentralness().map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <Form.Label>Negative</Form.Label>
            <Form.Select
              value={mapNegative}
              onChange={(e) => setMapNegative(e.currentTarget.value)}
            >
              {getMapNegative().map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
      </Form>
      <Row>
        <Col sm={5} className="mb-3">
          <h2>My bots</h2>
          <ListGroup>
            {myBots.map((bot) => (
              <ListGroup.Item
                key={bot.id}
                action
                active={myBot == bot}
                onClick={() => setMyBot(bot)}
              >
                {bot.name} ({bot.rating})
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col sm={2} />
        <Col sm={5}>
          <h2 className="text-end">Playable bots</h2>
          <ListGroup>
            {bots.map((bot) => (
              <ListGroup.Item
                key={bot.id}
                action
                active={otherBot == bot}
                onClick={() => setOtherBot(bot)}
              >
                {bot.name} ({bot.rating}){" "}
                {!bot.myBot &&
                  bot.challengePolicy == ChallengePolicy.CONFIRM && (
                    <i
                      title="The other player needs to confirm"
                      className="bi bi-exclamation-circle ms-2"
                    ></i>
                  )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}
