export function getMapSizes(): string[] {
  return ["small", "medium", "large"];
}

export function getMapBudgets(): string[] {
  return ["tight", "medium", "generous"];
}

export function getMapPens(): string[] {
  return ["few", "medium", "many"];
}

export function getMapCentralness(): string[] {
  return ["spread", "medium", "concentrated"];
}

export function getMapNegative(): string[] {
  return ["no", "yes"];
}

export function getAllMapCombinations(): string[] {
  return getMapSizes().flatMap((size) =>
    getMapBudgets().flatMap((budget) =>
      getMapPens().flatMap((pens) =>
        getMapCentralness().flatMap((centralness) =>
          getMapNegative().map(
            (negative) => `${size}-${budget}-${pens}-${centralness}-${negative}`
          )
        )
      )
    )
  );
}

export function normalizeMapName(mapName: string): string | null {
  const parts = validateMapName(mapName);
  if (parts == null) {
    return null;
  }
  return `${parts.size}-${parts.budget}-${parts.pens}-${parts.centralness}-${parts.negative}`;
}

export function validateMapName(mapName: string): MapNameParts | null {
  const parts = mapName.split("-");
  const size = parts[0];
  const budget = parts.length > 1 ? parts[1] : "medium";
  const pens = parts.length > 2 ? parts[2] : "medium";
  const centralness = parts.length > 3 ? parts[3] : "medium";
  const negative = parts.length > 4 ? parts[4] : "no";

  if (getMapSizes().indexOf(size) == -1) {
    return null;
  }
  if (getMapBudgets().indexOf(budget) == -1) {
    return null;
  }
  if (getMapPens().indexOf(pens) == -1) {
    return null;
  }
  if (getMapCentralness().indexOf(centralness) == -1) {
    return null;
  }
  if (getMapNegative().indexOf(negative) == -1) {
    return null;
  }
  return {
    size,
    budget,
    pens,
    centralness,
    negative,
  };
}

export interface MapNameParts {
  size: string;
  budget: string;
  pens: string;
  centralness: string;
  negative: string;
}
