import { Link, useNavigate } from "react-router-dom";
import {
  Bot,
  Confirmation,
  TournamentOverview,
  TournamentStatus,
} from "../../api/model";
import { useEffect, useState } from "react";
import * as api from "../../api/api";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import {
  botCodeStatusToIcon,
  tournamentToIcon,
} from "../../utils/bot-status-icons";
import { challengeStatusToIcon } from "../../utils/challenge-status-icons";
import Countdown from "../../components/Countdown";

export default function Dashboard() {
  const [bots, setBots] = useState<Bot[] | undefined>();
  const [tournaments, setTournaments] = useState<
    TournamentOverview[] | undefined
  >();
  const [openConfirmations, setOpenConfirmations] = useState<
    Confirmation[] | undefined
  >();
  const [sentConfirmations, setSentConfirmations] = useState<
    Confirmation[] | undefined
  >();
  const navigate = useNavigate();

  useEffect(() => {
    api.getMyBots().then(setBots);
    api.getTournaments().then(setTournaments);
    api.getOpenConfirmations().then(setOpenConfirmations);
    api.getSentConfirmations().then(setSentConfirmations);
  }, []);

  function createFirstBot() {
    api.addBot().then((bot) => {
      navigate(`/my-bots/${bot.id}`);
    });
  }

  function replyConfirmation(confirmation: Confirmation, accept: boolean) {
    api.replyConfirmation(confirmation.id, accept).then(() => {
      api.getOpenConfirmations().then(setOpenConfirmations);
    });
  }

  if (
    bots === undefined ||
    tournaments === undefined ||
    openConfirmations === undefined ||
    sentConfirmations === undefined
  ) {
    return <div>Loading...</div>;
  }

  const registeredBots = bots.filter(
    (bot) => bot.participateInTournaments
  ).length;

  const nextTournament = tournaments.filter(
    (tournament) => tournament.status === TournamentStatus.SCHEDULED
  )[0];

  return (
    <div>
      <h1>Colorclaim</h1>
      <div className="my-3">
        <a
          href="https://soi.ch/contests/2025/round1/colorclaim/"
          target="_blank"
          rel="noreferrer"
        >
          Task Description
        </a>
      </div>
      {bots.length === 0 && (
        <Button onClick={createFirstBot}>Create my first bot</Button>
      )}
      {bots.length > 0 && (
        <Row>
          <Col lg={3}>
            <Card className="mb-3">
              <Card.Header>My bots</Card.Header>
              <ListGroup variant="flush">
                {bots.map((bot) => (
                  <ListGroup.Item key={bot.id}>
                    <Link to={`/my-bots/${bot.id}`}>{bot.name}</Link>{" "}
                    {botCodeStatusToIcon(bot, true)} {tournamentToIcon(bot)}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="mb-3">
              <Card.Header>Next tournament</Card.Header>
              <Card.Body>
                <Card.Text>
                  {nextTournament && (
                    <>
                      The{" "}
                      <Link to={`/tournaments/${nextTournament.id}`}>
                        next tournament
                      </Link>{" "}
                      is scheduled for{" "}
                      {nextTournament.start.toLocaleString("de-CH")}, in{" "}
                      <Countdown target={nextTournament.start} />.
                    </>
                  )}
                  {!nextTournament &&
                    "There are no upcoming tournaments scheduled."}
                </Card.Text>
                <Card.Text>
                  {registeredBots === 0 && (
                    <i
                      title="You don't have any bots registered"
                      className="bi bi-exclamation-circle me-2"
                      style={{ color: "red" }}
                    ></i>
                  )}
                  You have {registeredBots} bot{registeredBots !== 1 && "s"}{" "}
                  registered for the next tournament.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={5}>
            <Card className="mb-3">
              <Card.Header>Open requests</Card.Header>
              {openConfirmations.length === 0 && (
                <Card.Body>
                  <Card.Text>
                    You don't have any open requests. Requests for playing
                    against your bots will show up here.
                  </Card.Text>
                </Card.Body>
              )}
              {openConfirmations.length > 0 && (
                <ListGroup variant="flush">
                  {openConfirmations.map((confirmation) => (
                    <ListGroup.Item key={confirmation.id}>
                      <div>
                        <Link to={`/play/${confirmation.challenger.id}`}>
                          {confirmation.challenger.name}
                        </Link>{" "}
                        ({confirmation.challenger.rating}) vs.{" "}
                        <Link to={`/my-bots/${confirmation.challenged.id}`}>
                          {confirmation.challenged.name}
                        </Link>{" "}
                        ({confirmation.challenged.rating})
                      </div>
                      <div>
                        Map:{" "}
                        <Link to={`/play/${confirmation.mapName}`}>
                          {confirmation.mapName}
                        </Link>
                      </div>
                      <div className="text-end mt-2">
                        <Button
                          size="sm"
                          variant="success"
                          onClick={() => replyConfirmation(confirmation, true)}
                        >
                          Accept
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          className="ms-3"
                          onClick={() => replyConfirmation(confirmation, false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card>
            <Card className="mb-3">
              <Card.Header>Sent requests</Card.Header>
              {sentConfirmations.length === 0 && (
                <Card.Body>
                  <Card.Text>You don't have any sent requests.</Card.Text>
                </Card.Body>
              )}
              {sentConfirmations.length > 0 && (
                <ListGroup variant="flush">
                  {sentConfirmations.map((confirmation) => (
                    <ListGroup.Item key={confirmation.id}>
                      <div>
                        {challengeStatusToIcon(confirmation.status, true)}{" "}
                        <Link to={`/my-bots/${confirmation.challenger.id}`}>
                          {confirmation.challenger.name}
                        </Link>{" "}
                        ({confirmation.challenger.rating}) vs.{" "}
                        <Link to={`/play/${confirmation.challenged.id}`}>
                          {confirmation.challenged.name}
                        </Link>{" "}
                        ({confirmation.challenged.rating})
                      </div>
                      <div>
                        Map:{" "}
                        <Link to={`/play/${confirmation.mapName}`}>
                          {confirmation.mapName}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
